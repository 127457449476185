
#checkoutPage {

  display: flex;

  .checkout-container {

    @media screen and (max-width: 720px) {
      height: auto;
      padding-bottom: 96px;
    }

    height: calc(100vh - 53px);
    overflow-y: auto;
    max-width: 720px;
    padding: 20px;
    border-right: 1px solid #eeeeee;
    flex-grow: 1;
  }

  .btn.btn-confirm {
    margin-top: 10px;
    font-size: 0.8rem;
    height: auto;
    padding: 8px 20px;
  }

  .address-list {

    font-size: 0.75rem;

    .address-entry {
      width: 100%;
      display: flex;
      border: 1px solid #eeeeee;
      padding: 10px 20px;
      font-weight: normal;
      color: black;
      margin-bottom: 10px;
      text-decoration: none;

      -webkit-appearance: none;
      -moz-appearance: none;
      background: transparent;
      text-align: left;
      outline: none;
    }

    .address-edit {
      padding: 5px 20px;
      align-self: center;

      a {
        color: #7d755a;
      }
    }

    .address-check {
      background: #eeeeee;
      width: 32px;
      height: 32px;
      color: #eeeeee;
      border-radius: 5px;
      text-align: center;
      padding-top: 6px;
    }

    .address-body {
      flex-grow: 1;
    }

    .address-input {
      align-self: center;
    }

    .address-title {
      font-weight: 500;
    }

    .address-summary {
      font-size: 0.65rem;
    }

    .address-entry:hover, .address-entry:focus {
      border-color: rgba(125, 117, 90, 0.6);
    }

    .address-entry.active {
      .address-check {
        color: white;
        background: #7d755a;
      }
    }

  }

}