
#authentication {

  min-height: 100vh;

  header {
    padding: 0.4rem 0.8rem;
  }

  .sign-in-footer {
    margin-bottom: 20px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .checkout-copyright {
      font-size: 0.7rem;
      opacity: 0.8;
      display: flex;
      justify-content: space-between;
      padding: 0 20px;

      a {
        margin-left: 10px;
      }
    }
  }

  .sign-in-header {
    margin-top: 20px;
    height: 80px;

    .company-logo {
      background-image: url("./matthys-wines.svg");
      width: 140px;
      height: 40px;
      background-position: center center;
      margin: auto;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .company-name {
      text-align: center;
      font-size: 0.75rem;
      font-weight: 500;
      color: #7d755a;
    }
  }

  .horeca-title {
    div:first-child {
      font-size: 1.8rem;
      line-height: 2rem;
      letter-spacing: 4px;
      font-weight: 200;
    }

    div:nth-child(2) {
      color: #c04c18;
      margin-top: 5px;
      font-size: 0.9rem;
    }

    margin-bottom: 32px;
  }

  input:focus, select:focus {
    outline: none;
    box-shadow: 0 0 3px rgba(179, 61, 50, 0.7);
    border-color: rgba(179, 61, 50, 0.7);
  }

  .btn.btn-primary {
    background-color: #c04c18;
    border-color: #c04c18;
    padding: 0.4rem 1rem;
    border-radius: .1rem;
    height: auto;
  }

  .btn.btn-primary:active {
    background-color: #B33D32;
  }

  .btn.btn-primary:hover, .btn.btn-primary:focus {
    outline: none;
    border-color: #B33D32;
    box-shadow: 0 0 3px rgba(179, 61, 50, 0.7);
  }

  .page-image {
    box-shadow: 5px 0 5px rgba(0, 0, 0, 0.2) inset;
    background-image: url("https://static.matthys-wines.com/images/prod/features/home/horeca-banner.jpg");
    background-size: cover;
    background-position: top center;
  }

  .auth-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 200px);

    .auth-body {
      padding: 3rem;
      max-width: 480px;
      align-self: center;
    }
  }

  .pin-input {
    letter-spacing: 30px;
    text-align: left;
    padding-left: calc(50% - 64px);
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 600;
  }

}
