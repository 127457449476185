#root {

  h1 {
    font-variant: small-caps;
    text-transform: lowercase;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 1px;
    color: #7e7e7e;
    margin-bottom: 32px;
    margin-top: 8px;
    text-align: center;
  }

  body {
    background: #ffffff !important;
    font-family: 'Open Sans', sans-serif;
    padding: 0;
    margin: 0;
  }

  .text-red {
    color: #B33D32;
  }

  a, .btn.btn-link {
    box-shadow: none;
  }

  .page-container {
    display: flex;

    .content-container {
      flex-grow: 1;
    }

  }

  .label.label-secondary {
    color: #7d755a;
    background: rgba(125, 117, 90, 0.05);
  }

  .menu .menu-item > a:focus, .menu .menu-item > a:hover {
    color: #7d755a;
    background: rgba(125, 117, 90, 0.05);
  }

  .btn {
    box-shadow: none;
  }

  .btn:not(.btn-primary) {
    border-color: #7d755a;
    color: #7d755a;
  }

  .btn:not(.btn-primary):hover, .btn:not(.btn-primary):focus {
    background: rgba(125, 117, 90, 0.1);
  }

  .btn:not(.btn-primary):active {
    color: white;
    background: rgba(125, 117, 90, 1);
  }

  .btn-primary {
    background: #7d755a;
    border-color: #7d755a;
  }

  .page-title {
    font-variant: small-caps;
    text-transform: lowercase;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 1px;
    color: #7e7e7e;
    margin-top: 8px;
    text-align: center;
    margin-bottom: 32px;
  }

  .btn-primary:hover, .btn-primary:focus {
    background: #9b9273;
    border-color: #9b9273;
  }

  .btn-primary:active {
    background: #625b47;
    border-color: #7d755a;
  }

}
