
.loader-breathing {
  background-image: url("glass_pattern.svg");
  background-size: 40px;

  animation-iteration-count: infinite;
  -webkit-animation-name: pulse; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 3s; /* Safari 4.0 - 8.0 */
  animation-name: pulse;
  animation-duration: 3s;
}

.loader-page {
  background-color: rgba(255,255,255,0.95);
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .loader-breathing {
    height: 100%;
  }
}

.loader-breathing.darker {
  -webkit-animation-name: pulse-darker;
  animation-name: pulse-darker;
}

.loader-section {
  padding: 0.5rem;
  margin: 0.5rem 0;
  background-color: #f8f8f8;

  .loader-breathing{
    width: 100%;
    height: 100%;
  }
}

.loader-content {
  width: 100%;
  height: 100%;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loader-container {
  width: 100%;
  height: 100vh;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  .loader-spinner {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }

  .loader-body {
    position: relative;
    z-index: 1001;
    display: flex;
    height: 100%;
    justify-content: space-around;

    .loader-content {
      align-self: center;
    }

    .loader-text {
      font-weight: 600;
      font-size: 0.8rem;
    }
  }
}

.loader-bottle {
  margin: auto;
  fill: red;
  height: 128px;
  width: 64px;
  display: block;

  animation-iteration-count: infinite;
  -webkit-animation-name: wine-colors; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 3s; /* Safari 4.0 - 8.0 */
  animation-name: wine-colors;
  animation-duration: 3s;
}

@keyframes wine-colors {
  0% {
    fill: #f2f6aa;
  }
  20% {
    fill: #f6b1a1;
  }
  40% {
    fill: rgba(248, 90, 76, 0.63);
  }
  60% {
    fill: rgba(202, 79, 136, 0.42);
  }
  80% {
    fill: #f8ebd2;
  }
  100% {
    fill: #f2f6aa;
  }
}

@keyframes pulse {
  0% {
    opacity: 0
  }
  50% {
    opacity: 0.5
  }
  100% {
    opacity: 0
  }
}

@-webkit-keyframes pulse {
  0% {
    opacity: 0
  }
  50% {
    opacity: 0.5
  }
  100% {
    opacity: 0
  }
}

@keyframes pulse-darker {
  0% {
    opacity: 0
  }
  50% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

@-webkit-keyframes pulse-darker {
  0% {
    opacity: 0
  }
  50% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}