header {

  padding: 0.4rem 0.8rem;
  border-bottom: 1px solid #eeeeee;
  position: fixed;
  top: env(safe-area-inset-top);
  z-index: 11000;
  left: 0;
  right: 0;
  background-color: white;

  .nav-user {
    font-size: 0.7rem;
    margin-right: 16px;
    color: #7c7c7c;

    display: none;
  }

  .btn-logout {
    font-size: 0.7rem;
    height: auto;
    border-color: #7d755a;
    color: #7d755a;
  }

  .btn-primary.btn-wine-list {
    font-size: 0.7rem;
    height: auto;
    display: none;
    background-color: #7d755a;
    color: white;
    @media screen and (max-width: 720px) {
      display: block;
    }
  }
}