#orderList {

  padding: 20px;

  table {
    width: 100%;
    margin: auto;
    border-collapse: collapse;
    max-width: 800px;
    font-size: 0.75rem;

    th {
      text-align: left;
      font-weight: 500;
      padding: 6px;
      border-bottom: 1px solid #eeeeee;
    }

    td {
      padding: 6px;
    }

    tr:not(:last-child) {
      border-bottom: 1px solid #eeeeee;
    }
  }

}