#navigationPane {

  border-right: 1px solid #eee;
  height: calc(100vh - 53px);
  overflow: auto;
  min-width: 320px;

  @media screen and (max-width: 720px) {
    height: auto;
  }

  @media screen and (max-width: 720px) {
    position: fixed;
    visibility: hidden;
    top: 53px;
    right: 0;
    left: 0;
    bottom: 0;
    max-width: none;
    z-index: 7500;
    background-color: white;
  }

  .menu-logo {
    padding-top: 16px;
    text-align: center;

    @media screen and (max-width: 720px) {
      display: none;
    }

    .company-logo {
      background-image: url("./matthys-wines.svg");
      width: 140px;
      height: 40px;
      background-position: center center;
      margin: auto;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .logo-name {
      color: #7d755a;
      font-size: 0.7rem;
      line-height: 0.75rem;
    }

    .logo-lead {
      font-size: 0.65rem;
    }
  }

  .nav-icon {
    display: inline-block;
    height: 16px;
    width: 16px;
    margin-bottom: -2px;
    margin-right: 8px;
  }

  .menu-spacer {
    height: 0;
  }

  .menu {
    box-shadow: none;

    li {
      font-size: 0.75rem;
    }

  }
}

#navigationPane.visible {
  visibility: visible;
}