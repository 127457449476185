#orderPage {

  .order-cart {
    margin-top: 10px;
  }

  padding: 20px;
  max-width: 800px;
  margin: auto;

  .section-title {
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 4px;
    padding-left: 4px;
    font-size: 0.7rem;
    margin-top: 20px;
  }

  .address-section {
    padding: 4px;
    font-size: 0.7rem;

    .address-title {
      font-weight: 400;
      font-size: 0.8rem;
    }
  }


}