#root {

  .mobile-cart-button-spacer {
    height: calc(env(safe-area-inset-bottom) + 64px);
  }

  .mobile-cart-button {

    display: none;
    @media screen and (max-width: 720px) {
      display: block;
    }

    padding: 8px;
    z-index: 5000;
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    left: 0;
    right: 0;
    background-color: transparent;

    button {
      width: 100%;
      -webkit-appearance: none;
      appearance: none;
      border: none;
      padding: 10px;
      color: white;
      cursor: pointer;
      background-color: #7d755a;
    }

    .btn-cart-open {
      background-image: url("./up-arrow.svg");
      background-position: 90% center;
      background-size: 20px;
      background-repeat: no-repeat;
      padding-left: 20px;

      .label.label-secondary {
        color: white;
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }

  #sideCart.visible {
    display: block;
  }

  #sideCart:not(.always-visible) {
    @media screen and (max-width: 720px) {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: white;
      z-index: 4500;
      padding-top: 96px;
      padding-bottom: 96px;
    }
  }

  #sideCart {

    padding: 20px;
    flex-grow: 1;
    max-height: calc(100vh);
    overflow-y: auto;
    padding-top: calc(env(safe-area-inset-top) + 20px);

    @media screen and (max-width: 720px) {
      height: auto;
    }

    @media screen and (max-width: 720px) {
      padding: 15px;
    }

    .cart-title {
      font-variant: small-caps;
      text-transform: lowercase;
      font-size: 1rem;
      font-weight: 300;
      letter-spacing: 1px;
      color: #7e7e7e;
      margin-bottom: 32px;
      margin-top: 8px;
    }

    .btn-submit {
      border-color: #7d755a;
      background-color: #7d755a;
      margin-top: 16px;
      font-size: 0.7rem;
      height: auto;
      padding-right: 16px;
      padding-left: 16px;
    }

    .cart-table {
      width: 100%;
      max-width: 640px;
      margin: auto;
      border-collapse: collapse;
    }

    .cart-table.full-width {
      max-width: none;
    }

    .cart-entry:not(:last-child) {
      td {
        border-bottom: 1px solid #eeeeee;
      }
    }

    .cart-header {
      th:nth-child(2) {
        text-align: left;
      }

      th:not(:nth-child(2)) {
        text-align: right;
        padding-right: 16px;
      }

      th {
        color: #bdbdbd;
        font-variant: small-caps;
        text-transform: lowercase;
        font-weight: 400;
      }
    }

    .cart-entry {
      .cart-item-btn {
        width: 0;
      }

      .cart-item-img {
        width: 48px;
        vertical-align: bottom;
        text-align: center;
      }

      .no-image {
        font-size: 0.8rem;
        font-weight: bold;
        padding-bottom: 10px;
      }

      .cart-item-name {
        @media screen and (max-width: 720px) {
          padding-left: 5px;
        }
        padding-top: 4px;
        padding-bottom: 4px;
        max-width: 420px;
        font-size: 0.75rem;
        padding-right: 16px;

        .wine-stock {
          font-size: 0.65rem;
        }

        .wine-stock.label-out-of-stock {
          color: #fc5c65;
        }

        .wine-stock.label-in-stock {
          color: #20bf6b;
        }
      }

      .cart-item-quantity {
        font-size: 0.7rem;
        padding-left: 16px;
        padding-right: 16px;
      }

      .cart-item-price {
        font-size: 0.7rem;
        padding-left: 16px;
        padding-right: 16px;
      }

      .btn-edit {
        margin: 2px 3px 2px 16px;
        border-color: #7d755a;
        color: #7d755a;
      }

      .btn-remove {
        border-color: #7d755a;
        color: #7d755a;
      }
    }

  }

  #sideCart.visible {
    display: block;
  }

}