#searchBar {

  width: 100%;

  .search-input-group {
    input {
      padding: 5px 12px;
      width: 100%;
      background-image: url("./search.svg");
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: calc(100% - 8px) center;
      border: 1px solid #7d755a;
      border-radius: 0;
      box-shadow: none !important;
      height: auto;
    }

    input:hover {
      background-color: rgba(125, 117, 90, 0.1);
    }

    input:focus {
      outline: none;
    }

  }

  .search-results {
    z-index: 1000;
    position: absolute;
    border: 1px solid #dfe6e9;
    right: 0;
    left: 0;
    background-color: white;
    min-width: 420px;
    max-height: 240px;
    overflow-y: auto;

    @media screen and (max-width: 720px) {
      position: fixed;
      top: 52px;
      left: 8px;
      right: 8px;
      min-width: auto;
    }

    .search-breadcrumbs {
      font-size: 0.6rem;
      color: #7a7a7a;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  .search-section {
    color: #444444;
    background-color: #dfe6e9;
    text-align: center;
    font-size: 0.7rem;
    padding: 6px 8px;
  }

  td:not(.search-section) {
    font-size: 0.7rem;
  }

  td {
    padding: 4px 8px;
    white-space: nowrap;

    a {
      color: #7d755a;
    }
  }

  tr:not(:last-child) {
    border-bottom: 1px solid #dfe6e9;
  }

  td:not(:first-child) {
    cursor: default;
    text-align: center;
    padding: 4px;
    min-width: 54px;
  }

  td:not(:last-child) {
    border-right: 1px solid white;
  }

  position: relative;

}