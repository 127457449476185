
#catalogPage {

  display: flex;

  .listing-container {
    height: calc(100vh - 53px);

    @media screen and (max-width: 720px) {
      height: auto;
    }
    overflow-y: auto;
    max-width: 720px;
    padding: 20px 12px;
    border-right: 1px solid #eeeeee;
    flex-grow: 1;


    @media screen and (max-width: 720px) {
      padding-top: calc(20px + env(safe-area-inset-top));
      padding-bottom: calc(env(safe-area-inset-bottom) + 50px);
    }
  }

  .country-header {
    font-variant: small-caps;
    text-transform: lowercase;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 1px;
    color: #7e7e7e;
    margin-top: 8px;
    text-align: center;
    margin-bottom: 32px;

    @media screen and (max-width: 720px) {
      margin-top: 4px;
      margin-bottom: 20px;
    }

    .country-icon {
      display: inline-block;
      height: 24px;
      width: 24px;
      margin-bottom: -6px;
      margin-right: 12px;
      margin-left: -12px;
      opacity: 1;
    }
  }


  .wine-listing {
    width: 100%;
    border-collapse: collapse;

    .wine-entry {

      .entry-img {
        @media screen and (max-width: 720px) {
          display: none;
        }
        width: 48px;
        vertical-align: bottom;
        text-align: center;
      }

      .no-image {
        font-size: 1rem;
        font-weight: bold;
        padding-bottom: 12px;
      }

      .entry-title {
        padding: 8px 32px 8px 0;
      }

      .entry-name {
        font-size: 0.75rem;
        font-weight: 500;
        max-width: 420px;
      }

      .entry-availability {
        font-size: 0.7rem;
        @media screen and (max-width: 720px) {
          font-size: 0.65rem;
        }
      }

      .entry-price {
        @media screen and (max-width: 720px) {
          display: none;
        }
        font-size: 0.75rem;
        padding-right: 32px;
      }

      .entry-padding {
        width: 8px;
      }

      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      .added-to-cart {
        animation: fadeIn 0.75s;
      }

      .units-in-cart {
        display: none;
        @media screen and (max-width: 720px) {
          display: block;
        }
        margin-top: 0.25rem;
        padding-right: 32px;
        color: #7c7c7c;
        font-size: 0.6rem;
      }

      .entry-btn-add {
        text-align: right;

        button {
          display: inline-block;
          height: auto;
          padding: 3px 6px;
          font-size: 0.7rem;
          margin: 2px;
        }
      }

      .availability-label.status-unknown {
        color: #fc5c65;
      }

      .availability-label.status-available {
        color: #20bf6b;
      }

      .availability-label.status-pending {
        color: #fd9644;
      }

    }

    td {
      border-bottom: 1px solid #eeeeee;
      border-top: 1px solid #eeeeee;
    }

    .sub-category {
      td {
        padding-top: 0.5rem;
        text-align: center;
        padding-bottom: 0.5rem;
        border-top: 1px solid #eeeeee;
        border-bottom: 1px solid #eeeeee;
        color: #7a7a7a;
        font-size: 0.8rem;
      }
    }

    .wine-entry.highlighted:nth-child(2) {;
      background-color: rgba(125, 117, 90, 0.1);
    }
  }
}
